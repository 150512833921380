@charset "UTF-8";

.p-1px {
  padding: 1px !important;
}

.p-2px {
  padding: 2px !important;
}

.m-auto {
  margin: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-0 {
  margin: 0rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.my-0 {
  margin: 0rem 0 !important;
}

.mx-0 {
  margin: 0 0rem !important;
}

.p-0 {
  padding: 0rem !important;
}

.py-0 {
  padding: 0rem 0 !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.px-0 {
  padding: 0 0rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mr-half {
  margin-right: 0.5rem;
}

.m-1 {
  margin: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.my-1 {
  margin: 1rem 0 !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.py-1 {
  padding: 1rem 0 !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.px-1 {
  padding: 0 1rem !important;
}

.m-2 {
  margin: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.my-2 {
  margin: 2rem 0 !important;
}

.mx-2 {
  margin: 0 2rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.py-2 {
  padding: 2rem 0 !important;
}

.py-2 {
  padding-top: 2rem !important;
}

.px-2 {
  padding: 0 2rem !important;
}

.m-3 {
  margin: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.my-3 {
  margin: 3rem 0 !important;
}

.mx-3 {
  margin: 0 3rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.py-3 {
  padding: 3rem 0 !important;
}

.py-3 {
  padding-top: 3rem !important;
}

.px-3 {
  padding: 0 3rem !important;
}

.m-4 {
  margin: 4rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.my-4 {
  margin: 4rem 0 !important;
}

.mx-4 {
  margin: 0 4rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.py-4 {
  padding: 4rem 0 !important;
}

.py-4 {
  padding-top: 4rem !important;
}

.px-4 {
  padding: 0 4rem !important;
}

.m-5 {
  margin: 5rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mr-5 {
  margin-right: 5rem !important;
}

.ml-5 {
  margin-left: 5rem !important;
}

.my-5 {
  margin: 5rem 0 !important;
}

.mx-5 {
  margin: 0 5rem !important;
}

.p-5 {
  padding: 5rem !important;
}

.py-5 {
  padding: 5rem 0 !important;
}

.py-5 {
  padding-top: 5rem !important;
}

.px-5 {
  padding: 0 5rem !important;
}

.py-half {
  padding: 0.5rem 0 !important;
}

/* ------------------ Color ------------------ */
.bg-blue {
  background-color: #0084ff !important;
  /* background-color:rgba(0, 0, 0, 0.767) */
}

.bg-white {
  background-color: white !important;
}
.text-gold {
  color: goldenrod;
}
.text-green {
  color: #28a745;
}
.text-dark {
  color: rgba(0, 0, 0, 0.4) !important;
}

.text-danger {
  color: rgb(224, 67, 67) !important;
}

.text-white {
  color: white !important;
}

.text-blue {
  color: #0084ff !important;
}

.text-info {
  color: goldenrod !important;
}

.text-gray {
  color: gray !important;
}

.text-black {
  color: black !important;
}

.text-success {
  color: #28a745;
}

.text-overallRating {
  color: #0084ff;
  background-color: white;
  padding: 2px 6px;
}

.text-decoration-none {
  text-decoration: none;
}

.border-blue {
  border: 1px solid #0084ff !important;
}

.background-blue {
  background-color: #0084ff !important;
}

.background-green {
  background-color: #28a745 !important;
}

.border-bottom {
  border-bottom: 1px solid black;
}

/* ------------------ Font ------------------- */
.text-bold {
  font-weight: bold;
}
.text-small {
  font-size: 0.75rem !important;
}

.text-1rem {
  font-size: 1rem;
}

.text-extraLarge {
  font-size: 4rem;
}

.text-align-right {
  text-align: right !important;
}
.text-align-left {
  text-align: left !important;
}
.text-align-center {
  text-align: center;
}

.text-crossed {
  text-decoration: line-through !important;
}

@media screen and (max-width: 699px) {
  .text-small {
    font-size: 0.6rem !important;
  }
}

/* ------------------- Display ------------------ */

.display-none {
  display: none !important;
}

.display-flex {
  display: flex !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.grid-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-two:first-child {
  margin-left: auto;
  margin-right: 1rem;
}

.grid-two:last-child {
  margin-right: auto;
  margin-left: 1rem;
}

@media screen and (max-width: 699px) {
  .grid-two {
    display: block;
  }

  .grid-two:first-child,
  .grid-two:last-child {
    margin: 1rem;
  }
}

/* ---------------------- Size --------------------------- */

.width-100 {
  width: 100px !important;
}

/* ------------------ Main Components ------------------ */

html,
body {
  margin: 0;
  padding: 0;
  /* background-color: black; */
  color: balck;
}

html {
  height: 100%;
}

#root {
  position: relative;
  height: 100%;
  /* min-height: calc(100vh - 40px); */
}

section {
  box-shadow: 0 5px 5px rgba(63, 63, 63, 0.493);
  padding: 8px;
  margin-bottom: 2rem;
}

input[type='date'] {
  width: 150px;
}

.section-title {
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 9rem auto 3rem;
  /* padding: 0.5rem 1rem; */
  z-index: initial;
  min-height: calc(100vh - 96px - 48px - 64px);
}

a {
  cursor: pointer;
}

.navigate-link {
  font-size: 1.25rem;
  color: #0084ff;
  text-decoration: none;
  border: 1px solid #0084ff;
  border-radius: 4px;
  padding: 4px 16px;
}

.navigate-link:hover {
  background-color: #0084ff;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.info-box,
.confirmation-box {
  /* display: block; */
  width: 400px;
  /* max-width: 95vw; */
  margin: 0 auto;
}

.info-box-title,
.confirmation-box h1 {
  position: relative;
  text-align: center;
  background-color: #0084ff;
}

.info-box-title h2 {
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.info-box-content {
  border: 1px solid black;
  border-top: none;
}

.info-box-content,
.confirmation-box p {
  /* background-color: black; */
  padding: 16px;
}

.confirmation-box h1 {
  margin: 0 auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.confirmation-box p {
  margin-top: 0;
}

.btns {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: 100%;
}

.btns button {
  padding: 8px 2rem;
}

.btns button:hover {
  background-color: #fff;
  color: #0084ff;
  font-size: 20px;
  padding: 6px 28px;
}

.btns :first-child {
  margin-left: auto;
}

.btns :last-child {
  margin-right: auto;
}
.btn-close {
  color: #0084ff;
  background: none;
  border: none;
  margin: 1rem 1rem 0 0;
  padding: 0.25rem 0.5rem;
  /* float: right; */
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.5rem;
  font-weight: bold;
  width: fit-content;
}

.btn-primary {
  background-color: #0084ff;
  color: white;
  font-size: 1.25rem;
  padding: 4px 32px;
}

/* ------------------ Alert ------------------ */

.alert {
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  width: calc(100% - 16px);
  padding: 0.8rem;
  margin: 0 auto;
  opacity: 0.9;
  background: #f4f4f4;
  color: #333;
  z-index: 99;
  text-align: center;
  font-weight: 100;
  font-size: 1.5rem;
}

.alert.alert-primary {
  background-color: #17a2b8;
  color: #fff;
}

.alert.alert-light {
  background-color: #f4f4f4;
  color: #333;
}

.alert.alert-dark {
  background-color: #28a745;
  color: #fff;
}

.alert.alert-success {
  background-color: #28a745;
  color: #fff;
}

.alert.alert-danger {
  background-color: #dc3545;
  color: #fff;
}

.alert.alert-white {
  background-color: white;
  color: #333;
  border: #ccc solid 1px;
}

/* ------------------- Login ------------------ */

.login-container {
  /* background-color: rgba(0, 0, 0, 0.6); */
  z-index: 20;
  height: 100vh;
  width: calc(100% - 16px);
  position: fixed;
  top: 72px;
  left: 0;
  align-items: center;
}

.login-window {
  z-index: 20;
  /* background-color: black; */
  position: relative;
  top: 60px;
  width: 400px;
  padding: 1rem;
  margin: 0 auto;
  border-radius: 15px;
  border: 1px solid gray;
}

.popup-title {
  display: flex;
  color: #0084ff;
}

.popup-title h1 {
  margin-top: 0;
  margin-right: auto;
}

.login-form-grid {
  display: grid;
  grid-template-columns: 35% 65%;
  padding-bottom: 8px;
}

.login-btn-grid-2 {
  width: 100%;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 50% 50%;
  /* gap: 8px; */
}

@media screen and (max-width: 699px) {
  .login-window {
    width: calc(100% - 64px) !important;
    top: 120px;
  }
}

/* --------------------------------  Main page  -------------------------------------- */
.box {
  text-align: center;
}
.main-entry-link {
  text-align: center;
  line-height: 150px;
  /* display: flex; */
  padding: 2rem;
  /* border: 1px solid black; */
  border-radius: 2rem;
  box-shadow: 0 10px lightblue;
  margin: 2rem;
  height: 200px;
  background-color: #0084ff;
  /* width: 100%; */
  font-size: 5rem;
  color: white;
  text-decoration: none;
}

.main-entry-link:hover {
  background-color: lightblue;
  color: black;
  box-shadow: 0 10px #0084ff;
}

.search-form {
  display: flex;
  width: 100%;
  margin: 3rem auto;
}

.search-form :first-child {
  margin-left: auto !important;
  margin-right: 1rem !important;
}

.search-form :last-child {
  margin-right: auto;
  margin-left: 1rem;
}

.search-form label,
.search-form input {
  font-size: 1.25rem;
  /* margin: auto 1rem; */
}

@media screen and (max-width: 699px) {
  .main-entry-link {
    display: block;
    height: 150px;
    font-size: 3rem;
  }
}

/* --------------------------------  New Item Form  ------------------------------------------- */

.new-item-form {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  border-bottom: 1px solid black;
  margin-bottom: 2rem;
  width: 100%;
}

.new-item-form label {
  font-size: 1.25rem;
  font-weight: bold;
  color: #0084ff;
  margin: 4px 16px 4px 0;
}

.new-item-form-coverImg-preview {
  max-width: 300px;
  object-fit: contain;
  margin-right: auto;
}

.new-item-form input {
  display: block;
  padding: 8px !important;
}

.new-item-form input[type='number'] {
  max-width: 100px;
}

.new-item-form-submit-btn {
  font-size: 2rem;
  padding: 8px 32px;
  margin: 2rem 0 auto;
  margin-left: auto;
  color: #0084ff;
  font-weight: bold;
  border: 1px solid #0084ff;
  border-radius: 8px;
  max-height: 100px;
}

.new-item-form-submit-btn:hover {
  color: white;
  background-color: #0084ff;
}

/* --------------------------------  Items/Transactions table  ------------------------------------ */
.items-table,
.transactions-table,
.new-item-table {
  width: 100%;
  overflow-y: scroll;
  border: 1px solid black;
  text-align: center;
}

.items-table thead,
.transactions-table thead,
.new-item-table thead {
  border-bottom: 1px solid black;
  background-color: #0084ff;
  color: white;
  font-size: 1.25rem;
}

.items-table-item {
  /* display: flex; */
  /* display: table-column-group; */
  /* max-width: 100%; */
}

.items-table-item-coverImg {
  max-width: 20vw;
}

.items-table-item-coverImg img {
  padding: 4px;
  object-fit: contain;
  height: 150px;
  max-width: 150px;
}

.statistics-table th,
.statistics-table td {
  padding-right: 1rem;
  text-align: center;
}

/* --------------------------------------  Transactions  --------------------------------------- */
.entry-form {
  /* display: flex; */
  font-size: 1.25rem;
  margin-top: 2rem;
}

.entry-form label {
  margin-left: 1rem;
}

.entry-form input {
  font-size: 1.25rem;
}

.entry-form input[type='number'],
.entry-form select {
  width: 100px;
  font-size: 1.25rem;
}

.entry-form select {
  color: white;
  margin-left: 1rem;
}

.long-input {
  width: auto !important;
}

.inputs {
  display: flex;
}

.select-fit-content {
  min-width: 150px !important;
  width: fit-content;
  font-size: 1.25rem;
  padding: 2px;
}
